/*  default
    ========================================================================== */
    .card {
        border-color: #999;
        .card-image {
            min-height: 34px;
            .card-image-link {
                .card-image-caption {
                    background-color: $primary;
                    .card-image-label {
                        background-color: transparent;
                        font-weight: 700;
                        text-transform: uppercase;
                        font-family: $font-family-roboto-condensed;
                    }
                }
            }
        }
        .card-body {
            .card-caption {
                margin-bottom: auto;
                padding: 10px;
                .card-title {
                    &::after {
                        content: "";
                        display: block;
                        max-width: 60px;
                        height: 2px;
                        margin: 5px 0 15px 0;
                        background-color: $yellow;
                    }
                }
                .card-description {
                    &:after {
                        display: none !important;
                    }
                }
            }
            .card-prices {
                padding: 0.45rem 0.75rem;
                width: 100%;
                background-color: $secondary;
                border-color: $secondary;
                color: $black;
                font-weight: 700;
                text-align: center;
                .card-price {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: center;
                    font-size: 18px;
                    &.discounted {
                        font-size: 14px;
                        text-decoration: line-through;
                    }
                    .card-price-description {
                        margin: 0 5px;
                        font-size: 14px;
                    }
                }
            }
            .card-buttons {
                margin-top: 5px;
                .card-btn {
                    width: 100%;
                    padding: 0.3rem 0.75rem;
                    border-color: #dedede;
                    background-color: #dedede;
                    color: $primary;
                    text-transform: uppercase;
                    border-radius: 0;
                    box-shadow: none;
                    font-weight: 700;
                    &:hover {
                        background-color: $primary;
                        border-color: $primary;
                        color: $white;
                    }
                }
            }
        }
    }

/*  home-blocks
    ========================================================================== */
    .home-blocks {
        .card {
            .card-body {
                .card-btn {
                    background-color: $secondary;
                    border-color: $secondary;
                    color: $black;
                }
            }
        }
    }
/*  bootstrap > colors
    ========================================================================== */
    $blue:          #004182;
    $blue-light:    #0071a1;
    $yellow:        #f0c640;
    $gray:          #999;
    $gray-light:    #eae9e9;

/*  bootstrap > theme-colors
    ========================================================================== */
    $primary:       $blue;
    $secondary:     $yellow;

/*  bootstrap > body
    ========================================================================== */
    $link-color:        $primary;
    $link-decoration:   underline;

/*  bootstrap > font
    ========================================================================== */
    @import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&family=Roboto:ital,wght@0,400;0,500;0,700;1,700&display=swap');

    $font-family-roboto: 'Roboto', sans-serif;
    $font-family-roboto-condensed: 'Roboto Condensed', sans-serif;
    $font-family-base: $font-family-roboto;

/* bootstrap > typography
    ========================================================================== */
    $headings-color: $blue;
    
/*  misc
    ========================================================================== */   
    
    h1, h2, h3, h4, h5, h6 {
        text-transform: uppercase;
    }

    /*
        h1
        24px bold uppercase / mobile: 20px 4px border
        5px border
        h2 
        24px bold / mobile: 20px
        3px border
        h3 
        18px 400 uppercase 
        card: 18px bold / same
              2px border
        h4 
        14px 400 uppercase
        0px border
        h5 
        16px bold uppercase
        3px border
    */

    /* transition */
    a,
    .btn,
    .owl-btn,
    .card-btn { transition: 0.5s !important;}
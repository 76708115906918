.header {
    .header-main {
        padding: 10px 15px;
        @include media-breakpoint-up(xl) {
            padding-bottom: 15px;
        }
        background-color: $white;
        box-shadow: 5px 5px 10px rgba($color: $black, $alpha: 0.15);
        .column.navbar { justify-content: flex-end;}
        .logo {
            position: absolute;
            top: -11px;
            left: 35px;
            max-width: 95px;
            @include media-breakpoint-up(xl) {
                max-width: 175px;
                left: 0;
            }
        }
        .direct-contact {
            align-items: center;
            background-color: $yellow;
            border-radius: 20px;
            margin: 0 0 0 auto;
            @include media-breakpoint-down(md) {
                display: none !important;
            }
            @include media-breakpoint-up(xl) {
                margin-bottom: 10px;
            }
            padding: 0 15px;
            color: #555;
            font-size: 14px;
            font-weight: 500;
            li {
                margin: 0;
                &:first-of-type {
                    margin-right: 5px;
                }
                i {
                    margin-right: 5px;
                } 
                a {
                    color: $black;
                    font-size: 20px;
                    font-weight: 700;
                    font-style: italic;
                    text-decoration: none;
                    &:hover {
                        color: $blue;
                    }
                }
            }
        }
        .direct-contact-mobile {
            @include media-breakpoint-up(lg) {
                display: none !important;
            }
            margin: 0 0 0 10px;
            li { margin-right: 0;
                a {
                    display: block;
                    width: 28px;
                    height: 28px;
                    padding: 0;
                    border-radius: 100%;
                    background-color: $secondary;
                    color: $black;
                    line-height: 28px;
                    text-align: center;
                    text-decoration: none;
                }
            }
        }
        .column.navbar {
            padding: 0;
            .navbar-toggler {
                display: flex;
                border: none;
                outline: none;
                margin: 0 auto 0 0;
                padding: 0;
                color: $blue;
                font-size: 24px;
                @include media-breakpoint-up(xl) {
                    display: none !important;
                }
            }
            .navbar-collapse {
                @include media-breakpoint-up(xl) {
                    flex-basis: 90%;
                }
            }
            .menu {
                @include media-breakpoint-down(lg) {
                    position: relative;
                    order: 5;
                    background-color: $white;
                }
                @include media-breakpoint-up(xl) {
                    margin-left: auto;
                }
                .navbar-nav {
                    margin-top: 8px;
                    .nav-item {
                        @include media-breakpoint-up(xl) {
                            margin-left: 20px;
                        }
                        font-size: 14px;
                        font-weight: 700;
                        text-transform: uppercase;
                        font-family: $font-family-roboto-condensed;
                        .nav-link {
                            padding: 0;
                            color: $primary;  
                            line-height: 24px;
                            text-decoration: none;
                            @include media-breakpoint-up(xl) {
                                border-bottom: 3px solid transparent;
                            }
                            &:hover {
                                border-color: $blue;
                                @include media-breakpoint-down(xl) {
                                    color: $blue;
                                }
                            } 
                        }
                        &.active a {
                            border-color: $blue;
                            @include media-breakpoint-down(lg) {
                                color: $blue;
                            }
                        }  
                        .dropdown-menu {
                            background-color: $primary;
                            @include media-breakpoint-up(xl) {
                                border: 2px solid $white;
                            }
                            margin: 0;
                            padding: 0;
                            .nav-item {
                                margin-left: 0;
                                text-transform: none;
                                .nav-link {
                                    margin-left: 0;
                                    padding: 5px 15px;
                                    color: $white;
                                    font-weight: 500;
                                    border-bottom: none;
                                    &:hover {
                                        color: $yellow;
                                    }
                                }
                                &.active a {
                                    color: $yellow;
                                } 
                            }
                        }
                    }
                }
                &.show {
                    .nav-item {
                        .nav-link {
                            line-height: 34px;
                        }
                    }
                }
            }
        }
        .language {
            flex-direction: row;
            margin-left: 15px;
            .nav-item {
                margin-left: 5px;
                .nav-link {
                    padding: 0 !important;
                    border: 1px solid $blue;
                    border-radius: 100%;
                    img { 
                        display: block;
                        width: 22px;
                        height: 22px;
                        @include media-breakpoint-down(lg) {
                            width: 26px;
                            height: 26px;
                        }
                        border-radius: 100%;
                    }
                    &:hover {
                        opacity: 0.8;
                    }
                }
            }
        }
    }

/*  sticky
    ========================================================================== */
    @include media-breakpoint-up(xl) {
        &.sticky {
            .header-main {
                .logo {
                    max-width: 125px;
                }
            }
        }
    }
}

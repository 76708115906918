/* ==========================================================================
   body
   ========================================================================== */
   $body-padding: 100px 0 0 0;
   $body-sticky-padding: 100px 0 0 0;

 
/* ==========================================================================
   containers
   ========================================================================== */
   $container-padding: 0 15px;
   

/* ==========================================================================
   content
   ========================================================================== */
   
   $content-padding: 6vh 0;

/*  content > h1
    ========================================================================== */
    $content-h1-font_size: 1.25rem; /* 20px */
    $content-h1-font_size_sm: 1.5rem; /* 24px */
    $content-h1-color: $headings-color;

/*  content > h2
    ========================================================================== */
    $content-h2-font_size: 1.25rem; /* 20px */
    $content-h2-font_size_sm: 1.5rem; /* 24px */
    $content-h2-color: $headings-color;

/*  content > h3
    ========================================================================== */
    $content-h3-font_size: 1.125rem; /* 18px */
    $content-h3-font_size_sm: 1.125rem; /* 18px */
    $content-h3-font_weight: 400;
    $content-h3-color: $headings-color;

/*  content > h4
    ========================================================================== */
    $content-h4-font_size: 0.875rem; /* 14px */
    $content-h4-font_size_sm: 0.875rem; /* 14px */
    $content-h4-font_weight: 400;
    $content-h4-color: $headings-color;

/*  content > h5
    ========================================================================== */
    $content-h5-font_size: 1rem; /* 16px */
    $content-h5-font_size_sm: 1rem; /* 16px */
    $content-h5-color: $headings-color;

/*  content > h6
    ========================================================================== */
    $content-h4-font_size: 0.875rem; /* 14px */
    $content-h4-font_size_sm: 0.875rem; /* 14px */
    $content-h6-color: $headings-color;


/*  content > ol.custom-list
    ========================================================================== */

    /* content > ol.custom-list > icon */
    $content-ol-custom-list-item-icon: '\f00c';
    $content-ol-custom-list-item-icon-font_size: $font-size-base;
    $content-ol-custom-list-item-icon-font_weight: $font-weight-bold;


/* ==========================================================================
    card
    ========================================================================== */
    $card-background: #fff;
    $card-base-border-radius: 0;
    $card-box_shadow: 6px 6px 6px rgba($black, 0.15);

/*  card > card-body
    ========================================================================== */
    $card-body-padding: 5px;

/*  card > card-caption
    ========================================================================== */
    $card-caption-flex: 0 1 auto;

    /* card > card-title */
    $card-title-font_size: $font-size-base * 1.125;
    $card-title-color: $primary;

    /* card > card-description */
    $card-description-font_size: $font-size-base * 0.875;
    $card-description-max_height: auto;
    $card-description-margin: 0 0 15px 0;
    $card-description-fade-background: linear-gradient(180deg, rgba(#ffffff,0) 0%, rgba(#ffffff,1) 100%);

/*  card > card-buttons
    ========================================================================== */
    $card-buttons-margin: auto 0 0 0;


/* ==========================================================================
    owl-carousel
    ========================================================================== */
  
    $carousel-min_height: 25vh ;
    $carousel-min_height_lg: 50vh;


/*  ==========================================================================
    footer
    ========================================================================== */
    
   $footer-top-padding: 0;
   $footer-top-background: none;
   

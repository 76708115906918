/*  home-blocks
    ========================================================================== */
    .home-blocks {
        padding: 6vh 0;
        background-color: $gray-light;
        .page_block.grid {
            justify-content: flex-start;
        }
    }

/*  content
    ========================================================================== */
    h1, h2 {
        &::after {
            content: "";
            display: block;
            max-width: 160px;
            height: 4px;
            @include media-breakpoint-up(xl) {
                font-size: 5px;
            }
            margin: 10px 0 25px 0;
            background-color: $yellow;
        }
    }
.eyecatcher {
	.owl-carousel {
		.owl-item.active .item .owl-caption { animation: 1s .25s slideInUp both;}
		.item {
			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: rgba($color: #000000, $alpha: 0.3);
			}
			.owl-caption {
				z-index: 100; 
				position: relative;
                color: $white;
				text-align: center;
				.owl-title {
					margin-bottom: 5px;
					font-size: 24px;
					font-weight: 400;
					text-shadow: none;
					text-transform: uppercase;
					font-family: $font-family-roboto-condensed;
					@include media-breakpoint-up(xl) {
                        font-size: 30px;
					}
				}
				.owl-subtitle {
					margin-bottom: 4vh;
					font-size: 18px;
					font-weight: 400;
					text-shadow: none;
					text-transform: uppercase;
					font-family: $font-family-roboto-condensed;
					@include media-breakpoint-up(xl) {
                        font-size: 20px;
					}
				}
				.owl-btn {
					padding: 0.7rem 0.75rem;
				}
			}
		}
	}
	h1 {
		z-index: 100;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 0;
		color: $white;
		font-size: 24px;
		font-weight: 400;
		font-family: $font-family-roboto-condensed;
		&::after { 
			display: none;
		}
		@include media-breakpoint-up(xl) {
			font-size: 30px;
		}
	}
}
.w3-location-map {
	h3 {
		@extend h1;
		margin-top: 10px !important;

		&:after {
			margin-bottom: 10px;
		}
	}

	.filters {
		display: flex;
		margin: 0;
	}

}


.mini-sab {
    position: relative;
    z-index: 998;
    margin-bottom: 110px;
    @include media-breakpoint-down(lg) {
        background: $blue;
        margin: -30px 7.5% 0 7.5%;
        padding: 15px 15px 0 15px;
    }
    @include media-breakpoint-up(xl) {
        height: 0;
        padding: 0;
    }
    .container { padding: 0;}
    .container-holder {
        color: $white;
        @include media-breakpoint-up(lg) {
            padding: 0;
        }
        @include media-breakpoint-up(xl) {
            z-index: 100;
            min-height: 80px;
            margin: -60px auto 0 auto;
            padding: 15px 15px 10px 15px;
            background: $blue;
        }
        label { 
            font-size: 14px;
            text-transform: uppercase;
            font-family: $font-family-roboto-condensed;
        }
        .btn {
            min-width: auto;
            font-weight: 700;
        }
    }
}
&.assortiment_overview {
	.content .column.one {
        @include make-col(3);
        @include media-breakpoint-down(sm) {
            flex: none;
            margin: 0;
            padding: 0;
        }
    }
	.content .column.two {
        @include make-col(12);
        @include media-breakpoint-up(md) {
            @include make-col(9);
        }
    }
    .sidebar-filter {
        font-size: 14px;
        h4 { font-family: $font-family-roboto-condensed;}
        .form-group { margin-bottom: 1.5rem;}
        @include media-breakpoint-down(sm) {
            display: none;
        }
        .heading {
            display: none;
            font-size: 20px;
        }
        .close-btn {
            display: none;
            position: absolute;
            top: 20px;
            right: 20px;
            color: $blue;
            font-size: 24px;
            line-height: 1;
        }
        &.open {
            display: block;
            position: fixed;
            z-index: 999;
            top: 0;
            left: 0;
            bottom: 0;
            width: 100%;
            max-width: 360px;
            height: 100%;
            padding: 20px 20px 75px 20px;
            background-color: rgba($color: #ffffff, $alpha: 0.99);
            overflow-x: hidden;
            .heading,
            .close-btn { display: block;}
        }
    }
    .page_block {
        &.grid {
            justify-content: flex-start;
            .item {
                @include media-breakpoint-up(lg) {
                    @include make-col(6);
                }
                @include media-breakpoint-up(xl) {
                    @include make-col(4);
                }
            }
        }
    }
    .fixed-filter-button {
        @include media-breakpoint-up(md) {
            display: none;
        }
        position: fixed;
        z-index: 998;
        left: 0;
        right: 0;
        bottom: 30px;
        text-align: center;
        .btn {
            min-width: 230px;
            margin: 0 auto;
            padding: 0.7rem 1rem;
            border-color: $white;
            border-radius: 5px;
            box-shadow: 6px 6px 6px rgba($color: #000000, $alpha: 0.15);
        }
    }
}
&.assortiment_detail {
	.assortiment_detail {
        margin-top: 60px;
        .btn-back { display: inline-block;}
        .prices {
            display: inline-block;
            margin-bottom: 30px;
            padding: 10px 15px;
            background-color: $secondary;
            border-color: $secondary;
            color: $black;
            font-size: 20px;
            font-weight: 700;
            .price {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .price-value { margin-right: 10px;}
                &.discount {
                    .price-description { font-size: 16px;}
                }
                &.discounted {
                    font-size: 14px;
                    text-decoration: line-through;
                }
            }
        }
        h3 {
            font-family: $font-family-roboto-condensed;
            &::after {
                content: "";
                display: block;
                max-width: 60px;
                height: 2px;
                margin: 5px 0 15px 0;
                background-color: $yellow;
            }
        }
        .description { margin: 30px 0;}
        .characteristics {
            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                li {
                    display: flex;
                    flex-wrap: wrap;
                    .label {
                        color: $gray;
                        flex-basis: 50%;
                    }
                }
            }

        }
        .downloads {
            margin: 30px 0;
            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                li {
                    display: flex;
                    flex-wrap: wrap;
                    .label {
                        color: $gray;
                        flex-basis: 50%;
                    }
                }
            }
        }
        .contact-form {
            margin-top: 30px;
            padding: 30px;
            border: 1px solid $gray;
            box-shadow: 6px 6px 6px rgba($color: #000000, $alpha: 0.15);
            h2 { text-transform: none;}
        }

		.youtube-item {
			.youtube-item-icon {
				display: flex;
				justify-content: center;
				align-items: center;
				position: absolute;
				top: 0; left: 0;
				width: 100%; height: 100%;
				font-size: 80px;
				color: white;

				i {
					background-color: $primary;
					border-radius: 50%;
					width: 72px;
					height: 72px;
					text-align: center;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				@include media-breakpoint-down(sm) {
					font-size: 40px;

					i {
						width: 36px;
						height: 36px;
					}
				}
			}

			img {
				height: 100%;
			}
		}
	}
}

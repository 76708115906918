&.service_detail {
    .main {
        padding-top: 5rem;
        
        @include media-breakpoint-down(sm) {
            padding-top: 1.5rem;
        }
    }

    .btn-back {
        text-decoration: none;
        margin-bottom: 1rem;
        display: inline-block;
    }
}
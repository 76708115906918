/*  footer
    ========================================================================== */
    .footer {
        position: relative;
        background: url('/images/footer-bg.jpg');
        background-position: center center;
        background-size: cover;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba($color: $blue, $alpha: 0.7);
        }
        .footer-top {
            padding: 6vh 0;
            color: #fff;
            font-size: 14px;
            h5 {
                color: #fff;
                font-size: 16px;
                &::after {
                    content: "";
                    display: block;
                    max-width: 60px;
                    height: 3px;
                    margin: 5px 0 15px 0;
                    background-color: $yellow;
                }
            }
            .contact-info {
                flex-direction: column;
                margin: 0;
                li {
                    margin: 0;
                    a {
                        color: $white;
                        text-decoration: none;
                        i {
                            margin-right: 5px;
                            color: $yellow;
                        }
                        &:hover {
                            color: $yellow;
                        }
                    }
                }
            }
        }
    }
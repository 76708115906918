.btn {
    min-width: 200px;
    padding: 0.375rem 1rem;
    border-radius: 5px;
    box-shadow: 6px 6px 6px rgba($color: $black, $alpha: 0.15);
    border-color: $primary;
    background-color: $primary;
    color: $white;
    font-size: 16px;
    font-weight: 400;
    text-transform: uppercase;
    font-family: $font-family-roboto-condensed;
    text-decoration: none;
    &.btn-primary {
        &:hover {
            background-color: lighten($primary, 10%);
            border-color: lighten($primary, 10%);
        }
    }
    &.btn-secondary {
        border-color: $secondary;
        background-color: $secondary;
        color: $black;
        &:hover {
            background-color: lighten($secondary, 10%);
            border-color: lighten($secondary, 10%);
        }
    }
}
/*  home-blocks
    ========================================================================== */
    &.home .home-blocks { padding: 6vh 0;}

/*  h2
    ========================================================================== */
    &.home h2 {
        font-size: 1.25rem; /* 20px */
        @include media-breakpoint-up(sm) {
            font-size: 1.5rem; /* 24px */
        }
    } 
